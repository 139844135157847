import axios from 'axios';
import { eventsTypes } from './events.types';

const getActiveEvents = () => (dispatch) => {
  dispatch({ type: eventsTypes.GET_ACTIVE_EVENTS_REQUEST });
  axios
    .get('/events/active')
    .then((response) => {
      dispatch({
        type: eventsTypes.GET_ACTIVE_EVENTS_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: eventsTypes.GET_ACTIVE_EVENTS_FAILURE,
        payload: { error },
      });
    });
};

const saveEvent = (data) => (dispatch) => {
  dispatch({ type: eventsTypes.SAVE_EVENT_REQUEST });
  axios
    .post('/events', data)
    .then((response) => {
      dispatch({
        type: eventsTypes.SAVE_EVENT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: eventsTypes.SAVE_EVENT_FAILURE,
        payload: { error },
      });
    });
};

const clearSaveEventsFields = () => (dispatch) => {
  dispatch({ type: eventsTypes.CLEAR_SAVE_EVENTS_FIELDS });
};

const getAnEvent = (id) => (dispatch) => {
  dispatch({ type: eventsTypes.GET_AN_EVENT_REQUEST });
  axios
    .get(`/events/${id}/id`)
    .then((response) => {
      dispatch({
        type: eventsTypes.GET_AN_EVENT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: eventsTypes.GET_AN_EVENT_FAILURE,
        payload: { error },
      });
    });
};

const getAllEvents = () => (dispatch) => {
  dispatch({ type: eventsTypes.GET_ALL_EVENTS_REQUEST });
  axios
    .get(`/events/get-all-events`)
    .then((response) => {
      dispatch({
        type: eventsTypes.GET_ALL_EVENTS_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: eventsTypes.GET_ALL_EVENTS_FAILURE,
        payload: { error },
      });
    });
};

const getEventsReport = () => (dispatch) => {
  dispatch({ type: eventsTypes.GET_EVENTS_REPORT_REQUEST });
  axios
    .get(`/events/get-report`)
    .then((response) => {
      dispatch({
        type: eventsTypes.GET_EVENTS_REPORT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: eventsTypes.GET_EVENTS_REPORT_FAILURE,
        payload: { error },
      });
    });
};

const updateEvent = (eventId, data) => (dispatch) => {
  dispatch({ type: eventsTypes.UPDATE_AN_EVENT_REQUEST });
  axios
    .patch(`/events/update/${eventId}`, data)
    .then((response) => {
      dispatch({
        type: eventsTypes.UPDATE_AN_EVENT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: eventsTypes.UPDATE_AN_EVENT_FAILURE,
        payload: { error },
      });
    });
};

const clearUpdateAnEventData = () => (dispatch) => {
  dispatch({ type: eventsTypes.CLEAR_UPDATE_AN_EVENT_DATA });
};

const deleteAnEvent = (eventId) => (dispatch) => {
  dispatch({ type: eventsTypes.DELETE_AN_EVENT_REQUEST });
  axios
    .delete(`/events/delete/${eventId}`)
    .then((response) => {
      dispatch({
        type: eventsTypes.DELETE_AN_EVENT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: eventsTypes.DELETE_AN_EVENT_FAILURE,
        payload: { error },
      });
    });
};

export const eventsActions = {
  getActiveEvents,
  saveEvent,
  clearSaveEventsFields,
  getAnEvent,
  getAllEvents,
  getEventsReport,
  updateEvent,
  clearUpdateAnEventData,
  deleteAnEvent,
};
