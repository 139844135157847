export const batchPosUpdateTypes = {
  CREATE_BATCH_UPDATE_REQUEST: 'CREATE_BATCH_UPDATE_REQUEST',
  CREATE_BATCH_UPDATE_SUCCESS: 'CREATE_BATCH_UPDATE_SUCCESS',
  CREATE_BATCH_UPDATE_FAILURE: 'CREATE_BATCH_UPDATE_FAILURE',

  CLEAR_CREATE_UPDATE_DATA: 'CLEAR_CREATE_UPDATE_DATA',

  GET_ALL_BATCH_UPDATE_REQUEST: 'GET_ALL_BATCH_UPDATE_REQUEST',
  GET_ALL_BATCH_UPDATE_SUCCESS: 'GET_ALL_BATCH_UPDATE_SUCCESS',
  GET_ALL_BATCH_UPDATE_FAILURE: 'GET_ALL_BATCH_UPDATE_FAILURE',

  GET_JOB_STATUS_REQUEST: 'GET_JOB_STATUS_REQUEST',
  GET_JOB_STATUS_SUCCESS: 'GET_JOB_STATUS_SUCCESS',
  GET_JOB_STATUS_FAILURE: 'GET_JOB_STATUS_FAILURE',

  DOWNLOAD_JOB_RESULT_REQUEST: 'DOWNLOAD_JOB_RESULT_REQUEST',
  DOWNLOAD_JOB_RESULT_SUCCESS: 'DOWNLOAD_JOB_RESULT_SUCCESS',
  DOWNLOAD_JOB_RESULT_FAILURE: 'DOWNLOAD_JOB_RESULT_FAILURE',

  CLEAR_DOWNLOAD_JOB_DATA: 'CLEAR_DOWNLOAD_JOB_DATA',

  GET_VA_REQUEST_POS_REQUEST: 'GET_VA_REQUEST_POS_REQUEST',
  GET_VA_REQUEST_POS_SUCCESS: 'GET_VA_REQUEST_POS_SUCCESS',
  GET_VA_REQUEST_POS_FAILURE: 'GET_VA_REQUEST_POS_FAILURE',

  BATCH_UPDATE_VA_POS_REQUEST: 'BATCH_UPDATE_VA_POS_REQUEST',
  BATCH_UPDATE_VA_POS_SUCCESS: 'BATCH_UPDATE_VA_POS_SUCCESS',
  BATCH_UPDATE_VA_POS_FAILURE: 'BATCH_UPDATE_VA_POS_FAILURE',

  CLEAR_BATCH_UPDATE_VA_POS: 'CLEAR_BATCH_UPDATE_VA_POS',

  MANUAL_UPDATE_POS_REQUEST: 'MANUAL_UPDATE_POS_REQUEST',
  MANUAL_UPDATE_POS_SUCCESS: 'MANUAL_UPDATE_POS_SUCCESS',
  MANUAL_UPDATE_POS_FAILURE: 'MANUAL_UPDATE_POS_FAILURE',

  CLEAR_MANUAL_UPDATE_POS_DATA: 'CLEAR_MANUAL_UPDATE_POS_DATA',

  GET_OUTDATED_VA_REQUEST: 'GET_OUTDATED_VA_REQUEST',
  GET_OUTDATED_VA_SUCCESS: 'GET_OUTDATED_VA_SUCCESS',
  GET_OUTDATED_VA_FAILURE: 'GET_OUTDATED_VA_FAILURE',

  MANUAL_BULK_UPLOAD_REQUEST: 'MANUAL_BULK_UPLOAD_REQUEST',
  MANUAL_BULK_UPLOAD_SUCCESS: 'MANUAL_BULK_UPLOAD_SUCCESS',
  MANUAL_BULK_UPLOAD_FAILURE: 'MANUAL_BULK_UPLOAD_FAILURE',

  SAVE_MANUAL_BULK_UPLOAD_REQUEST: 'SAVE_MANUAL_BULK_UPLOAD_REQUEST',
  SAVE_MANUAL_BULK_UPLOAD_SUCCESS: 'SAVE_MANUAL_BULK_UPLOAD_SUCCESS',
  SAVE_MANUAL_BULK_UPLOAD_FAILURE: 'SAVE_MANUAL_BULK_UPLOAD_FAILURE',
};
