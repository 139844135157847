import { eventsTypes } from './events.types';

const initialState = {
  events: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
  saveEvent: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
  getAnEvent: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  getAllEvents: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  getEventsReport: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  updateAnEvent: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  deleteAnEvent: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
};

export const events = (state = initialState, action = null) => {
  switch (action.type) {
    case eventsTypes.GET_ACTIVE_EVENTS_REQUEST:
      return {
        ...state,
        events: {
          ...state.events,
          loading: true,
          error: false,
          success: false,
        },
      };
    case eventsTypes.GET_ACTIVE_EVENTS_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case eventsTypes.GET_ACTIVE_EVENTS_FAILURE:
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case eventsTypes.SAVE_EVENT_REQUEST:
      return {
        ...state,
        saveEvent: {
          ...state.saveEvent,
          loading: true,
          error: false,
          success: false,
        },
      };
    case eventsTypes.SAVE_EVENT_SUCCESS:
      return {
        ...state,
        saveEvent: {
          ...state.saveEvent,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case eventsTypes.SAVE_EVENT_FAILURE:
      return {
        ...state,
        saveEvent: {
          ...state.saveEvent,
          loading: false,
          message: action.payload?.error?.response.data.message,
          error: true,
          success: false,
        },
      };
    case eventsTypes.CLEAR_SAVE_EVENTS_FIELDS:
      return {
        ...state,
        saveEvent: {
          ...state.saveEvent,
          loading: false,
          message: '',
          error: false,
          success: false,
        },
      };
    case eventsTypes.GET_AN_EVENT_REQUEST:
      return {
        ...state,
        getAnEvent: {
          ...state.getAnEvent,
          loading: true,
          message: '',
          error: false,
          success: false,
        },
      };
    case eventsTypes.GET_AN_EVENT_SUCCESS:
      return {
        ...state,
        getAnEvent: {
          ...state.getAnEvent,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
          error: false,
        },
      };
    case eventsTypes.GET_AN_EVENT_FAILURE:
      return {
        ...state,
        getAnEvent: {
          ...state.getAnEvent,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
          success: false,
        },
      };
    case eventsTypes.GET_ALL_EVENTS_REQUEST:
      return {
        ...state,
        getAllEvents: {
          ...state.getAllEvents,
          loading: true,
          message: '',
          error: false,
          success: false,
        },
      };
    case eventsTypes.GET_ALL_EVENTS_SUCCESS:
      return {
        ...state,
        getAllEvents: {
          ...state.getAllEvents,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
          error: false,
        },
      };
    case eventsTypes.GET_ALL_EVENTS_FAILURE:
      return {
        ...state,
        getAllEvents: {
          ...state.getAllEvents,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
          success: false,
        },
      };
    case eventsTypes.GET_EVENTS_REPORT_REQUEST:
      return {
        ...state,
        getEventsReport: {
          ...state.getEventsReport,
          loading: true,
          message: '',
          error: false,
          success: false,
        },
      };
    case eventsTypes.GET_EVENTS_REPORT_SUCCESS:
      return {
        ...state,
        getEventsReport: {
          ...state.getEventsReport,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
          error: false,
        },
      };
    case eventsTypes.GET_EVENTS_REPORT_FAILURE:
      return {
        ...state,
        getEventsReport: {
          ...state.getEventsReport,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
          success: false,
        },
      };
    case eventsTypes.UPDATE_AN_EVENT_REQUEST:
      return {
        ...state,
        updateAnEvent: {
          ...state.updateAnEvent,
          loading: true,
          message: '',
          error: false,
          success: false,
        },
      };
    case eventsTypes.UPDATE_AN_EVENT_SUCCESS:
      return {
        ...state,
        updateAnEvent: {
          ...state.updateAnEvent,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
          error: false,
        },
      };
    case eventsTypes.UPDATE_AN_EVENT_FAILURE:
      return {
        ...state,
        updateAnEvent: {
          ...state.updateAnEvent,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
          success: false,
        },
      };
    case eventsTypes.CLEAR_UPDATE_AN_EVENT_DATA:
      return {
        ...state,
        updateAnEvent: {
          ...state.updateAnEvent,
          loading: false,
          message: '',
          error: false,
          data: {},
          success: false,
        },
      };
    case eventsTypes.DELETE_AN_EVENT_REQUEST:
      return {
        ...state,
        deleteAnEvent: {
          ...state.deleteAnEvent,
          loading: true,
          message: '',
          error: false,
          success: false,
        },
      };
    case eventsTypes.DELETE_AN_EVENT_SUCCESS:
      return {
        ...state,
        deleteAnEvent: {
          ...state.deleteAnEvent,
          message: action.payload.response.data.message,
          loading: false,
          success: true,
          error: false,
        },
      };
    case eventsTypes.DELETE_AN_EVENT_FAILURE:
      return {
        ...state,
        deleteAnEvent: {
          ...state.deleteAnEvent,
          loading: false,
          message: action.payload?.error?.response?.data.message,
          error: true,
          success: false,
        },
      };
    default:
      return state;
  }
};
