export const syncLogTypes = {
  SAVE_SYCNLOG_RECORD_REQUEST: 'SAVE_SYCNLOG_RECORD_REQUEST',
  SAVE_SYCNLOG_RECORD_SUCCESS: 'SAVE_SYCNLOG_RECORD_SUCCESS',
  SAVE_SYCNLOG_RECORD_FAILURE: 'SAVE_SYCNLOG_RECORD_FAILURE',
  SAVE_SYCNLOG_RECORD_CLEAR: 'SAVE_SYCNLOG_RECORD_CLEAR',

  GET_ALL_SYNCLOG_RECORD_REQUEST: 'GET_ALL_SYNCLOG_RECORD_REQUEST',
  GET_ALL_SYNCLOG_RECORD_SUCCESS: 'GET_ALL_SYNCLOG_RECORD_SUCCESS',
  GET_ALL_SYNCLOG_RECORD_FAILURE: 'GET_ALL_SYNCLOG_RECORD_FAILURE',
  GET_ALL_SYNCLOG_RECORD_CLEAR: 'GET_ALL_SYNCLOG_RECORD_CLEAR',

  RUN_DATA_CLEARING_REQUEST: 'RUN_DATA_CLEARING_REQUEST',
  RUN_DATA_CLEARING_SUCCESS: 'RUN_DATA_CLEARING_SUCCESS',
  RUN_DATA_CLEARING_FAILURE: 'RUN_DATA_CLEARING_FAILURE',
  RUN_DATA_CLEARING_CLEAR: 'RUN_DATA_CLEARING_CLEAR',
};
