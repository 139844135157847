import { combineReducers } from 'redux';
import { authentication } from './authentication';
import { user } from './user';
import { events } from './events';
import { map } from './map';
import { virtualAssistants } from './virtualAssistants';
import { batchPosUpdate } from './batchPosUpdate';
import { apiTokens } from './apiTokens';
import { tptm } from './tptm';
import { dbBackup } from './dbBackup';
import { syncLog } from './syncLog';

export default combineReducers({
  authentication,
  user,
  events,
  map,
  virtualAssistants,
  batchPosUpdate,
  apiTokens,
  tptm,
  dbBackup,
  syncLog,
});
