import { dbBackupTypes } from './dbBackup.types';

const initialState = {
  dbBackup: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  getDbBackupReport: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
};

export const dbBackup = (state = initialState, action = null) => {
  switch (action.type) {
    case dbBackupTypes.GET_ALL_DBBACKUP_REQUEST:
      return {
        ...state,
        dbBackup: {
          ...state.dbBackup,
          loading: true,
          error: false,
          success: false,
        },
      };
    case dbBackupTypes.GET_ALL_DBBACKUP_SUCCESS:
      return {
        ...state,
        dbBackup: {
          ...state.dbBackup,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case dbBackupTypes.GET_ALL_DBBACKUP_FAILURE:
      return {
        ...state,
        dbBackup: {
          ...state.dbBackup,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case dbBackupTypes.GET_DBBACKUP_REPORT_REQUEST:
      return {
        ...state,
        getDbBackupReport: {
          ...state.getDbBackupReport,
          loading: true,
          error: false,
          success: false,
        },
      };
    case dbBackupTypes.GET_DBBACKUP_REPORT_SUCCESS:
      return {
        ...state,
        getDbBackupReport: {
          ...state.getDbBackupReport,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case dbBackupTypes.GET_DBBACKUP_REPORT_FAILURE:
      return {
        ...state,
        getDbBackupReport: {
          ...state.getDbBackupReport,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    default:
      return state;
  }
};
