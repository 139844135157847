export const virtualAssistantsTypes = {
  PAGINATE_VA_REQUEST: 'PAGINATE_VA_REQUEST',
  PAGINATE_VA_SUCCESS: 'PAGINATE_VA_SUCCESS',
  PAGINATE_VA_FAILURE: 'PAGINATE_VA_FAILURE',

  SYNC_MONDAY_TO_SQL_REQUEST: 'SYNC_MONDAY_TO_SQL_REQUEST',
  SYNC_MONDAY_TO_SQL_SUCCESS: 'SYNC_MONDAY_TO_SQL_SUCCESS',
  SYNC_MONDAY_TO_SQL_FAILURE: 'SYNC_MONDAY_TO_SQL_FAILURE',
  CLEAT_SYNC_MONDAY_TO_SQL_DATA: 'CLEAT_SYNC_MONDAY_TO_SQL_DATA',

  GET_VA_REPORT_REQUEST: 'GET_VA_REPORT_REQUEST',
  GET_VA_REPORT_SUCCESS: 'GET_VA_REPORT_SUCCESS',
  GET_VA_REPORT_FAILURE: 'GET_VA_REPORT_FAILURE',

  PAGENATE_OUTDATED_VA_REQUEST: 'PAGENATE_OUTDATED_VA_REQUEST',
  PAGENATE_OUTDATED_VA_SUCCESS: 'PAGENATE_OUTDATED_VA_SUCCESS',
  PAGENATE_OUTDATED_VA_FAILURE: 'PAGENATE_OUTDATED_VA_FAILURE',

  GET_HEREAPI_REPORT_REQUEST: 'GET_HEREAPI_REPORT_REQUEST',
  GET_HEREAPI_REPORT_SUCCESS: 'GET_HEREAPI_REPORT_SUCCESS',
  GET_HEREAPI_REPORT_FAILURE: 'GET_HEREAPI_REPORT_FAILURE',

  UPDATE_VA_LOCATIONS_REQUEST: 'UPDATE_VA_LOCATIONS_REQUEST',
  UPDATE_VA_LOCATIONS_SUCCESS: 'UPDATE_VA_LOCATIONS_SUCCESS',
  UPDATE_VA_LOCATIONS_FAILURE: 'UPDATE_VA_LOCATIONS_FAILURE',

  CLEAR_OUTDATED_VA_DATA: 'CLEAR_OUTDATED_VA_DATA',
  CLEAR_VA_DATA: 'CLEAR_VA_DATA',

  GET_VA_WITHIN_EVENT_REQUEST: 'GET_VA_WITHIN_EVENT_REQUEST',
  GET_VA_WITHIN_EVENT_SUCCESS: 'GET_VA_WITHIN_EVENT_SUCCESS',
  GET_VA_WITHIN_EVENT_FAILURE: 'GET_VA_WITHIN_EVENT_FAILURE',
  CLEAR_GET_VA_WITHIN_EVENT_DATA: 'CLEAR_GET_VA_WITHIN_EVENT_DATA',

  EXPORT_EVENT_RESULT_REQUEST: 'EXPORT_EVENT_RESULT_REQUEST',
  EXPORT_EVENT_RESULT_SUCCESS: 'EXPORT_EVENT_RESULT_SUCCESS',
  EXPORT_EVENT_RESULT_FAILURE: 'EXPORT_EVENT_RESULT_FAILURE',
  CLEAR_EXPORT_VA_WITHIN_EVENT_DATA: 'CLEAR_EXPORT_VA_WITHIN_EVENT_DATA',
};
