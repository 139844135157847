import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Header from './components/Header';
import { userActions } from './redux';
// import "antd/dist/antd.css"
import 'antd/dist/antd.min.css';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getUsersPermissions());
    dispatch(userActions.getUserRoles());
  }, []);

  return (
    <div className="min-h-full">
      <Header />
      {
        <div
          className="bg-white"
          // style={{ padding: 24, minHeight: 360 }}
        >
          <Outlet />
        </div>
      }
    </div>
  );
};

export default App;
