import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './index.css';
import axios from 'axios';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import routes from './routes/main.routes';
import { ProtectedRoute } from './components/ProtectedRoute';

const Login = React.lazy(() => import('./pages/Login'));

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post['content-type'] = 'application/json';
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;

// set this for unauthorized access
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 && error.response.data.message === 'Unauthenticated.') {
      localStorage.removeItem('token');
      return <Navigate to="/login" />;
    }
    return Promise.reject(error);
  }
);

// // Add a request interceptor
// axios.interceptors.request.use(
//     function (config) {
//         const token = localStorage.getItem('token');
//         if (!token) {
//             history.push('/login');
//         }
//         return config;
//     },
//     function (error) {
//         // Do something with request error
//         return Promise.reject(error);
//     }
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <App />
              </ProtectedRoute>
            }
          >
            {routes.map((route) => {
              const Component = route.component;
              return (
                <Route
                  path={route.route}
                  exact
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <Component />
                    </Suspense>
                  }
                  key={route.route}
                />
              );
            })}
            <Route path="/" exact element={<Navigate to="/map-dashboard" replace />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
