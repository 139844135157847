import axios from 'axios';
import { batchPosUpdateTypes } from './batchPosUpdate.types';

const createBatchUpdate = () => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.CREATE_BATCH_UPDATE_REQUEST });
  axios
    .post('/batch-geocoding/create-pos-batch-request')
    .then((response) => {
      dispatch({
        type: batchPosUpdateTypes.CREATE_BATCH_UPDATE_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: batchPosUpdateTypes.CREATE_BATCH_UPDATE_FAILURE,
        payload: { error },
      });
    });
};

const getAllBatchUpdate = () => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.GET_ALL_BATCH_UPDATE_REQUEST });
  axios
    .get('/batch-geocoding/all')
    .then((response) => {
      dispatch({
        type: batchPosUpdateTypes.GET_ALL_BATCH_UPDATE_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: batchPosUpdateTypes.GET_ALL_BATCH_UPDATE_FAILURE,
        payload: { error },
      });
    });
};

const getBatchJobStatus = (requestId) => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.GET_JOB_STATUS_REQUEST });
  axios
    .get(`batch-geocoding/get-batch-request-status/${requestId}/status`)
    .then((response) => {
      dispatch({
        type: batchPosUpdateTypes.GET_JOB_STATUS_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: batchPosUpdateTypes.GET_JOB_STATUS_FAILURE,
        payload: { error },
      });
    });
};

const clearCreateBatchUpdateData = () => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.CLEAR_CREATE_UPDATE_DATA });
};

const getDownloadBatchJob = (requestId) => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.DOWNLOAD_JOB_RESULT_REQUEST });
  axios
    .get(`batch-geocoding/get-batch-request-result/${requestId}/result`)
    .then((response) => {
      dispatch({
        type: batchPosUpdateTypes.DOWNLOAD_JOB_RESULT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: batchPosUpdateTypes.DOWNLOAD_JOB_RESULT_FAILURE,
        payload: { error },
      });
    });
};

const clearGetDownloadBatchJob = () => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.CLEAR_DOWNLOAD_JOB_DATA });
};

const getVaRequestPosList = (id) => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.GET_VA_REQUEST_POS_REQUEST });
  axios
    .get(`batch-geocoding/get-requested-vas/${id}`)
    .then((response) => {
      dispatch({
        type: batchPosUpdateTypes.GET_VA_REQUEST_POS_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: batchPosUpdateTypes.GET_VA_REQUEST_POS_FAILURE,
        payload: { error },
      });
    });
};

const updateBatchPos = (data) => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.BATCH_UPDATE_VA_POS_REQUEST });
  axios
    .post('batch-geocoding/batch-update-pos', data)
    .then((response) => {
      dispatch({
        type: batchPosUpdateTypes.BATCH_UPDATE_VA_POS_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: batchPosUpdateTypes.BATCH_UPDATE_VA_POS_FAILURE,
        payload: { error },
      });
    });
};

const clearBatchUpdateVaPosData = () => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.CLEAR_BATCH_UPDATE_VA_POS });
};

const manualUpdatePos =
  (requestId, vaId, markerPos = null) =>
  (dispatch) => {
    dispatch({ type: batchPosUpdateTypes.MANUAL_UPDATE_POS_REQUEST });
    axios
      .patch('batch-geocoding/manual-update-pos', { requestId, vaId, markerPos })
      .then((response) => {
        dispatch({
          type: batchPosUpdateTypes.MANUAL_UPDATE_POS_SUCCESS,
          payload: { response },
        });
      })
      .catch((error) => {
        dispatch({
          type: batchPosUpdateTypes.MANUAL_UPDATE_POS_FAILURE,
          payload: { error },
        });
      });
  };

const clearManualPosUpdateData = () => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.CLEAR_MANUAL_UPDATE_POS_DATA });
};

const getAllOutdatedVa = () => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.GET_OUTDATED_VA_REQUEST });
  axios
    .get('batch-geocoding/get-all-outdated-vas')
    .then((response) => {
      dispatch({
        type: batchPosUpdateTypes.GET_OUTDATED_VA_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: batchPosUpdateTypes.GET_OUTDATED_VA_FAILURE,
        payload: { error },
      });
    });
};

const manualBulkUploadPos = (data) => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.MANUAL_BULK_UPLOAD_REQUEST });
  axios
    .patch('batch-geocoding/manual-bulk-update-pos', data)
    .then((response) => {
      dispatch({
        type: batchPosUpdateTypes.MANUAL_BULK_UPLOAD_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: batchPosUpdateTypes.MANUAL_BULK_UPLOAD_FAILURE,
        payload: { error },
      });
    });
};

const saveManualBulkUploadPos = (data) => (dispatch) => {
  dispatch({ type: batchPosUpdateTypes.SAVE_MANUAL_BULK_UPLOAD_REQUEST });
  axios
    .patch('batch-geocoding/save-manual-bulk-update-pos', data)
    .then((response) => {
      dispatch({
        type: batchPosUpdateTypes.SAVE_MANUAL_BULK_UPLOAD_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: batchPosUpdateTypes.SAVE_MANUAL_BULK_UPLOAD_FAILURE,
        payload: { error },
      });
    });
};

export const batchPosUpdateAction = {
  createBatchUpdate,
  getAllBatchUpdate,
  getBatchJobStatus,
  clearCreateBatchUpdateData,
  getDownloadBatchJob,
  clearGetDownloadBatchJob,
  getVaRequestPosList,
  updateBatchPos,
  clearBatchUpdateVaPosData,
  manualUpdatePos,
  clearManualPosUpdateData,
  getAllOutdatedVa,
  manualBulkUploadPos,
  saveManualBulkUploadPos,
};
