import axios from 'axios';
import { userTypes } from './user.types';

const getAllUsers = () => (dispatch) => {
  dispatch({ type: userTypes.GET_ALL_USERS_REQUEST });
  axios
    .get('/users/all-users')
    .then((response) => {
      dispatch({
        type: userTypes.GET_ALL_USERS_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: userTypes.GET_ALL_USERS_FAILURE,
        payload: { error },
      });
    });
};

const getUsersReport = () => (dispatch) => {
  dispatch({ type: userTypes.GET_USER_REPORT_REQUEST });
  axios
    .get('/users/get-report')
    .then((response) => {
      dispatch({
        type: userTypes.GET_USER_REPORT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: userTypes.GET_USER_REPORT_FAILURE,
        payload: { error },
      });
    });
};

const updateUser = (data) => (dispatch) => {
  dispatch({ type: userTypes.UPDATE_USER_REQUEST });
  axios
    .patch(`/users/update/${data.id}`, data)
    .then((response) => {
      dispatch({
        type: userTypes.UPDATE_USER_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: userTypes.UPDATE_USER_FAILURE,
        payload: { error },
      });
    });
};

const createUser = (data) => (dispatch) => {
  dispatch({ type: userTypes.CREATE_NEW_USER_REQUEST });
  axios
    .post('/users/create', data)
    .then((response) => {
      dispatch({
        type: userTypes.CREATE_NEW_USER_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: userTypes.CREATE_NEW_USER_FAILURE,
        payload: { error },
      });
    });
};

const getUsersPermissions = () => (dispatch) => {
  dispatch({ type: userTypes.GET_USER_PERMISSIONS_REQUEST });
  axios
    .get('/users/get-all-permissions')
    .then((response) => {
      dispatch({
        type: userTypes.GET_USER_PERMISSIONS_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: userTypes.GET_USER_PERMISSIONS_FAILURE,
        payload: { error },
      });
    });
};

const getUserRoles = () => (dispatch) => {
  dispatch({ type: userTypes.GET_USER_ROLES_REQUEST });
  axios
    .get('/users/get-roles')
    .then((response) => {
      dispatch({
        type: userTypes.GET_USER_ROLES_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: userTypes.GET_USER_ROLES_FAILURE,
        payload: { error },
      });
    });
};

export const userActions = {
  getAllUsers,
  getUsersReport,
  updateUser,
  createUser,
  getUsersPermissions,
  getUserRoles,
};
