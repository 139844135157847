import axios from 'axios';
import { dbBackupTypes } from './dbBackup.types';

const getAllDbBackupRecords = () => (dispatch) => {
  dispatch({ type: dbBackupTypes.GET_ALL_DBBACKUP_REQUEST });
  axios
    .get('/db-backup')
    .then((response) => {
      dispatch({
        type: dbBackupTypes.GET_ALL_DBBACKUP_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: dbBackupTypes.GET_ALL_DBBACKUP_FAILURE,
        payload: { error },
      });
    });
};

const getDbBackupReport = () => (dispatch) => {
  dispatch({ type: dbBackupTypes.GET_DBBACKUP_REPORT_REQUEST });
  axios
    .get('/db-backup/get-report')
    .then((response) => {
      dispatch({
        type: dbBackupTypes.GET_DBBACKUP_REPORT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: dbBackupTypes.GET_DBBACKUP_REPORT_FAILURE,
        payload: { error },
      });
    });
};

export const dbBackupActions = {
  getAllDbBackupRecords,
  getDbBackupReport,
};
