import { syncLogTypes } from './syncLog.types';

const initialState = {
  saveSyncLog: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  getSyncLog: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  dataClearing: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
};

export const syncLog = (state = initialState, action = null) => {
  switch (action.type) {
    case syncLogTypes.SAVE_SYCNLOG_RECORD_REQUEST:
      return {
        ...state,
        saveSyncLog: {
          ...state.saveSyncLog,
          loading: true,
          error: false,
          success: false,
        },
      };
    case syncLogTypes.SAVE_SYCNLOG_RECORD_SUCCESS:
      return {
        ...state,
        saveSyncLog: {
          ...state.saveSyncLog,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case syncLogTypes.SAVE_SYCNLOG_RECORD_FAILURE:
      return {
        ...state,
        saveSyncLog: {
          ...state.saveSyncLog,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case syncLogTypes.SAVE_SYCNLOG_RECORD_CLEAR:
      return {
        ...state,
        saveSyncLog: {
          ...state.saveSyncLog,
          loading: false,
          message: '',
          error: false,
          data: {},
          success: false,
        },
      };
    case syncLogTypes.GET_ALL_SYNCLOG_RECORD_REQUEST:
      return {
        ...state,
        getSyncLog: {
          ...state.getSyncLog,
          loading: true,
          error: false,
          success: false,
        },
      };
    case syncLogTypes.GET_ALL_SYNCLOG_RECORD_SUCCESS:
      return {
        ...state,
        getSyncLog: {
          ...state.getSyncLog,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case syncLogTypes.GET_ALL_SYNCLOG_RECORD_FAILURE:
      return {
        ...state,
        getSyncLog: {
          ...state.getSyncLog,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case syncLogTypes.GET_ALL_SYNCLOG_RECORD_CLEAR:
      return {
        ...state,
        getSyncLog: {
          ...state.getSyncLog,
          loading: false,
          message: '',
          error: false,
          data: [],
          success: false,
        },
      };
    case syncLogTypes.RUN_DATA_CLEARING_REQUEST:
      return {
        ...state,
        dataClearing: {
          ...state.dataClearing,
          loading: true,
          error: false,
          success: false,
        },
      };
    case syncLogTypes.RUN_DATA_CLEARING_SUCCESS:
      return {
        ...state,
        dataClearing: {
          ...state.dataClearing,
          message: action.payload.response.data.message,
          loading: false,
          success: true,
        },
      };
    case syncLogTypes.RUN_DATA_CLEARING_FAILURE:
      return {
        ...state,
        dataClearing: {
          ...state.dataClearing,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case syncLogTypes.RUN_DATA_CLEARING_CLEAR:
      return {
        ...state,
        dataClearing: {
          ...state.dataClearing,
          loading: false,
          message: '',
          error: false,
          success: false,
        },
      };
    default:
      return state;
  }
};
