import { batchPosUpdateTypes } from './batchPosUpdate.types';

const initialState = {
  createBatchUpdate: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  getBatchUpdate: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  getBatchJobStatus: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  getDownloadBatchJob: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  getVaPosRequestedList: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  updateBatchPos: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
  manualUpdatePos: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
  getAllOutdatedVa: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  manualBulkUploadPos: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
  saveManualBulkUploadPos: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
};

export const batchPosUpdate = (state = initialState, action = null) => {
  switch (action.type) {
    case batchPosUpdateTypes.CREATE_BATCH_UPDATE_REQUEST:
      return {
        ...state,
        createBatchUpdate: {
          ...state.createBatchUpdate,
          loading: true,
          error: false,
          success: false,
        },
      };
    case batchPosUpdateTypes.CREATE_BATCH_UPDATE_SUCCESS:
      return {
        ...state,
        createBatchUpdate: {
          ...state.createBatchUpdate,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case batchPosUpdateTypes.CREATE_BATCH_UPDATE_FAILURE:
      return {
        ...state,
        createBatchUpdate: {
          ...state.createBatchUpdate,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case batchPosUpdateTypes.CLEAR_CREATE_UPDATE_DATA:
      return {
        ...state,
        createBatchUpdate: {
          ...state.createBatchUpdate,
          loading: false,
          message: '',
          error: false,
          data: {},
          success: false,
        },
      };
    case batchPosUpdateTypes.GET_ALL_BATCH_UPDATE_REQUEST:
      return {
        ...state,
        getBatchUpdate: {
          ...state.getBatchUpdate,
          loading: true,
          error: false,
          success: false,
        },
      };
    case batchPosUpdateTypes.GET_ALL_BATCH_UPDATE_SUCCESS:
      return {
        ...state,
        getBatchUpdate: {
          ...state.getBatchUpdate,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case batchPosUpdateTypes.GET_ALL_BATCH_UPDATE_FAILURE:
      return {
        ...state,
        getBatchUpdate: {
          ...state.getBatchUpdate,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case batchPosUpdateTypes.GET_JOB_STATUS_REQUEST:
      return {
        ...state,
        getBatchJobStatus: {
          ...state.getBatchJobStatus,
          loading: true,
          error: false,
          success: false,
        },
      };
    case batchPosUpdateTypes.GET_JOB_STATUS_SUCCESS:
      return {
        ...state,
        getBatchJobStatus: {
          ...state.getBatchJobStatus,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case batchPosUpdateTypes.GET_JOB_STATUS_FAILURE:
      return {
        ...state,
        getBatchJobStatus: {
          ...state.getBatchJobStatus,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case batchPosUpdateTypes.DOWNLOAD_JOB_RESULT_REQUEST:
      return {
        ...state,
        getDownloadBatchJob: {
          ...state.getDownloadBatchJob,
          loading: true,
          error: false,
          success: false,
        },
      };
    case batchPosUpdateTypes.DOWNLOAD_JOB_RESULT_SUCCESS:
      return {
        ...state,
        getDownloadBatchJob: {
          ...state.getDownloadBatchJob,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case batchPosUpdateTypes.DOWNLOAD_JOB_RESULT_FAILURE:
      return {
        ...state,
        getDownloadBatchJob: {
          ...state.getDownloadBatchJob,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case batchPosUpdateTypes.CLEAR_DOWNLOAD_JOB_DATA:
      return {
        ...state,
        getDownloadBatchJob: {
          ...state.getDownloadBatchJob,
          loading: false,
          message: '',
          error: false,
          data: {},
          success: false,
        },
      };
    case batchPosUpdateTypes.GET_VA_REQUEST_POS_REQUEST:
      return {
        ...state,
        getVaPosRequestedList: {
          ...state.getVaPosRequestedList,
          loading: true,
          error: false,
          success: false,
        },
      };
    case batchPosUpdateTypes.GET_VA_REQUEST_POS_SUCCESS:
      return {
        ...state,
        getVaPosRequestedList: {
          ...state.getVaPosRequestedList,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case batchPosUpdateTypes.GET_VA_REQUEST_POS_FAILURE:
      return {
        ...state,
        getVaPosRequestedList: {
          ...state.getVaPosRequestedList,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case batchPosUpdateTypes.BATCH_UPDATE_VA_POS_REQUEST:
      return {
        ...state,
        updateBatchPos: {
          ...state.updateBatchPos,
          loading: true,
          error: false,
          success: false,
        },
      };
    case batchPosUpdateTypes.BATCH_UPDATE_VA_POS_SUCCESS:
      return {
        ...state,
        updateBatchPos: {
          ...state.updateBatchPos,
          message: action.payload.response.data.message,
          loading: false,
          success: true,
        },
      };
    case batchPosUpdateTypes.BATCH_UPDATE_VA_POS_FAILURE:
      return {
        ...state,
        updateBatchPos: {
          ...state.updateBatchPos,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case batchPosUpdateTypes.CLEAR_BATCH_UPDATE_VA_POS:
      return {
        ...state,
        updateBatchPos: {
          ...state.updateBatchPos,
          loading: false,
          message: '',
          error: false,
          success: false,
        },
      };
    case batchPosUpdateTypes.MANUAL_UPDATE_POS_REQUEST:
      return {
        ...state,
        manualUpdatePos: {
          ...state.manualUpdatePos,
          loading: true,
          error: false,
          success: false,
        },
      };
    case batchPosUpdateTypes.MANUAL_UPDATE_POS_SUCCESS:
      return {
        ...state,
        manualUpdatePos: {
          ...state.manualUpdatePos,
          message: action.payload.response.data.message,
          loading: false,
          success: true,
        },
      };
    case batchPosUpdateTypes.MANUAL_UPDATE_POS_FAILURE:
      return {
        ...state,
        manualUpdatePos: {
          ...state.manualUpdatePos,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          // message: action.payload?.error?.message,
          error: true,
        },
      };
    case batchPosUpdateTypes.CLEAR_MANUAL_UPDATE_POS_DATA:
      return {
        ...state,
        manualUpdatePos: {
          ...state.manualUpdatePos,
          loading: false,
          message: '',
          error: false,
          success: false,
        },
      };
    case batchPosUpdateTypes.GET_OUTDATED_VA_REQUEST:
      return {
        ...state,
        getAllOutdatedVa: {
          ...state.getAllOutdatedVa,
          loading: true,
          error: false,
          success: false,
        },
      };
    case batchPosUpdateTypes.GET_OUTDATED_VA_SUCCESS:
      return {
        ...state,
        getAllOutdatedVa: {
          ...state.getAllOutdatedVa,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case batchPosUpdateTypes.GET_OUTDATED_VA_FAILURE:
      return {
        ...state,
        getAllOutdatedVa: {
          ...state.getAllOutdatedVa,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case batchPosUpdateTypes.MANUAL_BULK_UPLOAD_REQUEST:
      return {
        ...state,
        manualBulkUploadPos: {
          ...state.manualBulkUploadPos,
          loading: true,
          error: false,
          success: false,
        },
      };
    case batchPosUpdateTypes.MANUAL_BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        manualBulkUploadPos: {
          ...state.manualBulkUploadPos,
          message: action.payload.response.data.message,
          data: action.payload.response.data?.data,
          loading: false,
          success: true,
        },
      };
    case batchPosUpdateTypes.MANUAL_BULK_UPLOAD_FAILURE:
      return {
        ...state,
        manualBulkUploadPos: {
          ...state.manualBulkUploadPos,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case batchPosUpdateTypes.SAVE_MANUAL_BULK_UPLOAD_REQUEST:
      return {
        ...state,
        saveManualBulkUploadPos: {
          ...state.saveManualBulkUploadPos,
          loading: true,
          error: false,
          success: false,
        },
      };
    case batchPosUpdateTypes.SAVE_MANUAL_BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        saveManualBulkUploadPos: {
          ...state.saveManualBulkUploadPos,
          message: action.payload.response.data.message,
          loading: false,
          success: true,
        },
      };
    case batchPosUpdateTypes.SAVE_MANUAL_BULK_UPLOAD_FAILURE:
      return {
        ...state,
        saveManualBulkUploadPos: {
          ...state.saveManualBulkUploadPos,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    default:
      return state;
  }
};
