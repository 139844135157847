import axios from 'axios';
import { virtualAssistantsTypes } from './virtualAssistants.types';

const getVirtualAssistants = (all, needPosUpdate, offset) => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.PAGINATE_VA_REQUEST });
  axios
    .get(`/virtual-assistants/get-hva-offset/${all}/all/${needPosUpdate}/need-pos-update/${offset}/offset`)
    .then((response) => {
      dispatch({
        type: virtualAssistantsTypes.PAGINATE_VA_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: virtualAssistantsTypes.PAGINATE_VA_FAILURE,
        payload: { error },
      });
    });
};

const getFilteredVirtualAssistants = (queryFilters) => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.PAGINATE_VA_REQUEST });
  axios
    .get('/virtual-assistants', {
      params: queryFilters,
    })
    .then((response) => {
      dispatch({
        type: virtualAssistantsTypes.PAGINATE_VA_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: virtualAssistantsTypes.PAGINATE_VA_FAILURE,
        payload: { error },
      });
    });
};

const getOutdatedPosVirtualAssistants = (offset) => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.PAGENATE_OUTDATED_VA_REQUEST });
  axios
    .get(`/virtual-assistants/get-hva-offset/0/all/1/need-pos-update/${offset}/offset`)
    .then((response) => {
      dispatch({
        type: virtualAssistantsTypes.PAGENATE_OUTDATED_VA_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: virtualAssistantsTypes.PAGENATE_OUTDATED_VA_FAILURE,
        payload: { error },
      });
    });
};

const syncMondayToSql = (vaType, pageNumber) => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.SYNC_MONDAY_TO_SQL_REQUEST });
  axios
    .post(`/monday-api/sync-to-sql/${vaType}/va-type/${pageNumber}/page-number`, {})
    .then((response) => {
      dispatch({
        type: virtualAssistantsTypes.SYNC_MONDAY_TO_SQL_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: virtualAssistantsTypes.SYNC_MONDAY_TO_SQL_FAILURE,
        payload: { error },
      });
    });
};

const clearSyncMondaySqlData = () => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.CLEAT_SYNC_MONDAY_TO_SQL_DATA });
};

const getVaReport = () => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.GET_VA_REPORT_REQUEST });
  axios
    .get('/virtual-assistants/get-report')
    .then((response) => {
      dispatch({
        type: virtualAssistantsTypes.GET_VA_REPORT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: virtualAssistantsTypes.GET_VA_REPORT_FAILURE,
        payload: { error },
      });
    });
};

const getHereApiReport = () => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.GET_HEREAPI_REPORT_REQUEST });
  axios
    .get('/virtual-assistants/get-hereapi-report')
    .then((response) => {
      dispatch({
        type: virtualAssistantsTypes.GET_HEREAPI_REPORT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: virtualAssistantsTypes.GET_HEREAPI_REPORT_FAILURE,
        payload: { error },
      });
    });
};

const updateVaLocations = () => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.UPDATE_VA_LOCATIONS_REQUEST });
  axios
    .post('/virtual-assistants/update-va-pos', {})
    .then((response) => {
      dispatch({
        type: virtualAssistantsTypes.UPDATE_VA_LOCATIONS_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: virtualAssistantsTypes.UPDATE_VA_LOCATIONS_FAILURE,
        payload: { error },
      });
    });
};

const clearOutdatedVaData = () => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.CLEAR_OUTDATED_VA_DATA });
};

const clearVaData = () => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.CLEAR_VA_DATA });
};

const getVaWithinEvent = (eventId, vaFilterSelected) => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.GET_VA_WITHIN_EVENT_REQUEST });
  axios
    .get(`/virtual-assistants/${eventId}/event/${vaFilterSelected}/va-filter`)
    .then((response) => {
      dispatch({
        type: virtualAssistantsTypes.GET_VA_WITHIN_EVENT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: virtualAssistantsTypes.GET_VA_WITHIN_EVENT_FAILURE,
        payload: { error },
      });
    });
};

const clearGetVaWithinEventData = () => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.CLEAR_GET_VA_WITHIN_EVENT_DATA });
};

const exportVaWithinEvent = (eventId, vaFilterSelected) => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.EXPORT_EVENT_RESULT_REQUEST });
  axios
    .get(`/virtual-assistants/export/${eventId}/event/${vaFilterSelected}/va-filter`)
    .then((response) => {
      dispatch({
        type: virtualAssistantsTypes.EXPORT_EVENT_RESULT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: virtualAssistantsTypes.EXPORT_EVENT_RESULT_FAILURE,
        payload: { error },
      });
    });
};

const clearExportVaWithinEventData = () => (dispatch) => {
  dispatch({ type: virtualAssistantsTypes.CLEAR_EXPORT_VA_WITHIN_EVENT_DATA });
};

export const virtualAssistantsActions = {
  getVirtualAssistants,
  syncMondayToSql,
  clearSyncMondaySqlData,
  getVaReport,
  getOutdatedPosVirtualAssistants,
  getHereApiReport,
  updateVaLocations,
  clearOutdatedVaData,
  clearVaData,
  getVaWithinEvent,
  clearGetVaWithinEventData,
  exportVaWithinEvent,
  clearExportVaWithinEventData,
  getFilteredVirtualAssistants,
};
