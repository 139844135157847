import React from 'react';

import { PieChartOutlined } from '@ant-design/icons';

const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Geolocation = React.lazy(() => import('../pages/Geolocation'));
const VirtualAssistants = React.lazy(() => import('../pages/VirtualAssistants'));
const Users = React.lazy(() => import('../pages/Users'));

const routes = [
  {
    name: 'Map Dashboard',
    icon: <PieChartOutlined />,
    route: '/map-dashboard',
    permissions: [],
    roles: ['user', 'admin'],
    component: Geolocation,
  },
  {
    name: 'Virtual Assistants',
    icon: <PieChartOutlined />,
    route: '/virtual-assistants-records',
    permissions: [],
    roles: ['user', 'admin'],
    component: VirtualAssistants,
  },
  {
    name: 'App Info',
    icon: <PieChartOutlined />,
    route: '/app-info',
    permissions: [],
    roles: ['admin'],
    component: Dashboard,
    hide: true,
  },
  {
    name: 'Users',
    icon: null,
    route: '/users',
    permissions: [],
    roles: ['admin'],
    component: Users,
  },
];

export default routes;
