import { userTypes } from './user.types';

const initialState = {
  users: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  usersReport: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  updateUser: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
  createUser: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
  usersPermissions: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  userRoles: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  userInfo: {
    name: '',
    email: '',
  },
};

export const user = (state = initialState, action = null) => {
  switch (action.type) {
    case userTypes.GET_ALL_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          error: false,
          success: false,
        },
      };
    case userTypes.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case userTypes.GET_ALL_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case userTypes.GET_USER_REPORT_REQUEST:
      return {
        ...state,
        usersReport: {
          ...state.usersReport,
          loading: true,
          error: false,
          success: false,
        },
      };
    case userTypes.GET_USER_REPORT_SUCCESS:
      return {
        ...state,
        usersReport: {
          ...state.usersReport,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case userTypes.GET_USER_REPORT_FAILURE:
      return {
        ...state,
        usersReport: {
          ...state.usersReport,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case userTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          loading: true,
          error: false,
          success: false,
        },
      };
    case userTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          message: action.payload.response.data.message,
          loading: false,
          success: true,
        },
      };
    case userTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          loading: false,
          message: action.payload?.error?.response.data.message,
          error: true,
        },
      };
    case userTypes.CREATE_NEW_USER_REQUEST:
      return {
        ...state,
        createUser: {
          ...state.createUser,
          loading: true,
          error: false,
          success: false,
        },
      };
    case userTypes.CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        createUser: {
          ...state.createUser,
          message: action.payload.response.data.message,
          loading: false,
          success: true,
        },
      };
    case userTypes.CREATE_NEW_USER_FAILURE:
      return {
        ...state,
        createUser: {
          ...state.createUser,
          loading: false,
          message: action.payload?.error?.response.data.message,
          error: true,
          errors: action.payload?.error?.response.data.errors,
        },
      };
    case userTypes.GET_USER_PERMISSIONS_REQUEST:
      return {
        ...state,
        usersPermissions: {
          ...state.usersPermissions,
          loading: true,
          error: false,
          success: false,
        },
      };
    case userTypes.GET_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        usersPermissions: {
          ...state.usersPermissions,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case userTypes.GET_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        usersPermissions: {
          ...state.usersPermissions,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case userTypes.GET_USER_ROLES_REQUEST:
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          loading: true,
          error: false,
          success: false,
        },
      };
    case userTypes.GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
        userInfo: {
          name: action.payload.response.data?.user.name,
          email: action.payload.response.data?.user.email,
        },
      };
    case userTypes.GET_USER_ROLES_FAILURE:
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    default:
      return state;
  }
};
