import React, { useEffect, useState } from 'react';
import { Button, notification, Popover } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import Logo from '../../img/logo.png';
import routes from '../../routes/main.routes';
import { authActions } from '../../redux';

const MainHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPathName, setCurrentPathName] = useState('');
  const [dropdown, setDropdown] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(
    'origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in duration-75 opacity-0 scale-95'
  );

  const logoutData = useSelector((state) => state.authentication.logout);
  const userRoles = useSelector((state) => state.user.userRoles);
  const userInfo = useSelector((state) => state.user.userInfo);

  const handleProfileDropdown = () => {
    /**
     * Profile dropdown temporary fix
     */
    if (dropdown) {
      setProfileDropdown(
        'origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-out duration-100 opacity-100 scale-100'
      );
    } else {
      setProfileDropdown(
        'origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in duration-75 opacity-0 scale-95'
      );
    }
    setDropdown(!dropdown);
  };

  const handleSignOut = () => {
    dispatch(authActions.logout());
  };

  useEffect(() => {
    setCurrentPathName(window.location.pathname);
  }, []);

  useEffect(() => {
    if (logoutData.error && logoutData.message) {
      notification.error({
        message: logoutData.message,
        description: '',
      });
    }

    if (logoutData.success && logoutData.message) {
      notification.success({
        message: logoutData.message,
        description: '',
      });
      dispatch(authActions.clearAuthFields());
      navigate('/login');
    }
  }, [logoutData.error, logoutData.success, logoutData.message]);

  return (
    <nav className="fixed w-full z-30 top-0 text-white bg-gray-800" style={{ zIndex: 1005 }}>
      <div className="mx-auto px-10">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            {/* <div className="flex-shrink-0">
              <img className="h-10 w-10 rounded-full" src={Logo} alt="Workflow" />
            </div> */}
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <div className="ml-3 relative">
                  <div>
                    <Popover
                      placement="bottomLeft"
                      title={
                        <span>
                          <UserOutlined /> {userInfo.name}
                          <br />
                          <MailOutlined /> {userInfo.email}
                        </span>
                      }
                      content={
                        <Button
                          type="link"
                          className="block px-4 py-2 text-sm text-gray-700"
                          onClick={handleSignOut}
                          loading={logoutData.loading}
                        >
                          Sign Out
                        </Button>
                      }
                      trigger="click"
                    >
                      <button
                        type="button"
                        className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm outline-none ring-2 ring-offset-2 ring-offset-gray-800 ring-gray-500 focus:ring-blue-800"
                        id="user-menu-button"
                        aria-expanded="false"
                        aria-haspopup="true"
                        // onClick={handleProfileDropdown}
                      >
                        <img className="h-8 w-8 rounded-full" src={Logo} alt="" />
                      </button>
                    </Popover>
                  </div>
                  {/* <div
                    className={profileDropdown}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabIndex="-1"
                  >
                    <Button
                      type="link"
                      className="block px-4 py-2 text-sm text-gray-700"
                      onClick={handleSignOut}
                      loading={logoutData.loading}
                    >
                      Sign Out
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {/* <a
                                        href="#"
                                        className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                                        aria-current="page"
                                    >
                                        Dashboard
                                    </a>

                                    <a
                                        href="#"
                                        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    >
                                        Team
                                    </a> */}
                {routes.map(
                  (route) =>
                    userRoles.data?.some((role) => route.roles?.includes(role)) &&
                    !route?.hide && (
                      <Link
                        to={route.route}
                        key={route.route}
                        className={
                          route.route === currentPathName
                            ? `bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium`
                            : `text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium`
                        }
                        // aria-current="page"
                        onClick={() => setCurrentPathName(route.route)}
                      >
                        {route.name}
                      </Link>
                    )
                )}
              </div>
            </div>
          </div>

          {/* <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              {/* <button
                                    type="button"
                                    className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                >
                                    <span className="sr-only">
                                        View notifications
                                    </span>
                                    <svg
                                        className="h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                        />
                                    </svg>
                                </button> * /}

              {/* <!-- Profile dropdown --> * /}
              <div className="ml-3 relative">
                <div>
                  <button
                    type="button"
                    className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm outline-none ring-2 ring-offset-2 ring-offset-gray-800 ring-gray-500 focus:ring-blue-800"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                    onClick={handleProfileDropdown}
                  >
                    <span className="sr-only">Open user menu</span>
                    <img className="h-8 w-8 rounded-full" src={Logo} alt="" />
                  </button>
                </div>
                <div
                  // className={`origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-${
                  //     dropdown ? "out" : "in"
                  // } duration-${
                  //     dropdown ? "100" : "75"
                  // } transform opacity-${
                  //     dropdown ? "100" : "0"
                  // } scale-${dropdown ? "100" : "95"}`}
                  className={profileDropdown}
                  // ease-out duration-100 opacity-100 scale-100
                  // ease-in duration-75 opacity-0 scale-95

                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex="-1"
                >
                  <Button
                    type="link"
                    className="block px-4 py-2 text-sm text-gray-700"
                    onClick={handleSignOut}
                    loading={logoutData.loading}
                  >
                    Sign Out
                  </Button>
                </div>
              </div>
            </div>
          </div> */}

          <div className="-mr-2 flex md:hidden">
            {/* <!-- Mobile menu button --> */}
            <button
              type="button"
              className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>

              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
              </svg>

              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* <!-- Mobile menu, show/hide based on menu state. --> */}
      {/* <div className="md:hidden" id="mobile-menu">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <a
                            href="#"
                            className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                            aria-current="page"
                        >
                            Dashboard
                        </a>

                        <a
                            href="#"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                        >
                            Team
                        </a>

                        <a
                            href="#"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                        >
                            Projects
                        </a>

                        <a
                            href="#"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                        >
                            Calendar
                        </a>

                        <a
                            href="#"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                        >
                            Reports
                        </a>
                    </div>

                    <div className="pt-4 pb-3 border-t border-gray-700">
                        <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                                <img
                                    className="h-10 w-10 rounded-full"
                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    alt=""
                                />
                            </div>
                            <div className="ml-3">
                                <div className="text-base font-medium leading-none text-white">
                                    Tom Cook
                                </div>
                                <div className="text-sm font-medium leading-none text-gray-400">
                                    tom@example.com
                                </div>
                            </div>
                            <button
                                type="button"
                                className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            >
                                <span className="sr-only">View notifications</span>
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="mt-3 px-2 space-y-1">
                            <a
                                href="#"
                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                            >
                                Your Profile
                            </a>

                            <a
                                href="#"
                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                            >
                                Settings
                            </a>

                            <a
                                href="#"
                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                            >
                                Sign out
                            </a>
                        </div>
                    </div>
                </div> */}
    </nav>
  );
};

export default MainHeader;
