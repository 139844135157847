import { authTypes } from './authentication.types';

const initialState = {
  permissions: [],
  login: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
  logout: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
};

export const authentication = (state = initialState, action = null) => {
  switch (action.type) {
    case authTypes.LOGIN_REQUEST:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
          error: false,
          success: false,
        },
      };
    case authTypes.LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          message: action.payload.response.data.message,
          loading: false,
          success: true,
        },
      };
    case authTypes.LOGIN_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          message: action.payload?.error?.response.data.message,
          error: true,
        },
      };
    case authTypes.LOGIN_CLEAR_FIELDS:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          message: '',
          error: false,
          success: false,
        },
      };
    case authTypes.LOGOUT_REQUEST:
      return {
        ...state,
        logout: {
          ...state.login,
          loading: true,
          error: false,
          success: false,
        },
      };
    case authTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        logout: {
          ...state.login,
          message: action.payload?.response?.data?.message,
          loading: false,
          success: true,
        },
      };
    case authTypes.LOGOUT_FAILURE:
      return {
        ...state,
        logout: {
          ...state.login,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case authTypes.LOGOUT_CLEAR_FIELDS:
      return {
        ...state,
        logout: {
          ...state.login,
          loading: false,
          message: '',
          error: false,
          success: false,
        },
      };
    default:
      return state;
  }
};
