export const apiTokensTypes = {
  GET_API_TOKEN_REQUEST: 'GET_API_TOKEN_REQUEST',
  GET_API_TOKEN_SUCCESS: 'GET_API_TOKEN_SUCCESS',
  GET_API_TOKEN_FAILURE: 'GET_API_TOKEN_FAILURE',

  CREATE_API_TOKEN_REQUEST: 'CREATE_API_TOKEN_REQUEST',
  CREATE_API_TOKEN_SUCCESS: 'CREATE_API_TOKEN_SUCCESS',
  CREATE_API_TOKEN_FAILURE: 'CREATE_API_TOKEN_FAILURE',

  REVOKE_TOKEN_REQUEST: 'REVOKE_TOKEN_REQUEST',
  REVOKE_TOKEN_SUCCESS: 'REVOKE_TOKEN_SUCCESS',
  REVOKE_TOKEN_FAILURE: 'REVOKE_TOKEN_FAILURE',
  CLEAR_REVOKE_TOKEN_DATA: 'CLEAR_REVOKE_TOKEN_DATA',
};
