import { apiTokensTypes } from './apiTokens.types';

const initialState = {
  getApiTokens: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  saveApiTokens: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  revokeToken: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
};

export const apiTokens = (state = initialState, action = null) => {
  switch (action.type) {
    case apiTokensTypes.GET_API_TOKEN_REQUEST:
      return {
        ...state,
        getApiTokens: {
          ...state.getApiTokens,
          loading: true,
          error: false,
          success: false,
        },
      };
    case apiTokensTypes.GET_API_TOKEN_SUCCESS:
      return {
        ...state,
        getApiTokens: {
          ...state.getApiTokens,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case apiTokensTypes.GET_API_TOKEN_FAILURE:
      return {
        ...state,
        getApiTokens: {
          ...state.getApiTokens,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case apiTokensTypes.CREATE_API_TOKEN_REQUEST:
      return {
        ...state,
        saveApiTokens: {
          ...state.saveApiTokens,
          loading: true,
          error: false,
          success: false,
        },
      };
    case apiTokensTypes.CREATE_API_TOKEN_SUCCESS:
      return {
        ...state,
        saveApiTokens: {
          ...state.saveApiTokens,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case apiTokensTypes.CREATE_API_TOKEN_FAILURE:
      return {
        ...state,
        saveApiTokens: {
          ...state.saveApiTokens,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case apiTokensTypes.REVOKE_TOKEN_REQUEST:
      return {
        ...state,
        revokeToken: {
          ...state.revokeToken,
          loading: true,
          error: false,
          success: false,
        },
      };
    case apiTokensTypes.REVOKE_TOKEN_SUCCESS:
      return {
        ...state,
        revokeToken: {
          ...state.revokeToken,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case apiTokensTypes.REVOKE_TOKEN_FAILURE:
      return {
        ...state,
        revokeToken: {
          ...state.revokeToken,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case apiTokensTypes.CLEAR_REVOKE_TOKEN_DATA:
      return {
        ...state,
        revokeToken: {
          loading: false,
          message: '',
          error: false,
          data: {},
          success: false,
        },
      };
    default:
      return state;
  }
};
