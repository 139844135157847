import { virtualAssistantsTypes } from './virtualAssistants.types';

const initialState = {
  hva: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
    hasMore: true,
  },
  outdatedPosHva: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
    hasMore: true,
  },
  syncMondayToSql: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  getVaReport: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  getHereApiReport: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  updateVaLocations: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
  vaWithinEvent: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  exportVaWithinEvent: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
};

export const virtualAssistants = (state = initialState, action = null) => {
  switch (action.type) {
    case virtualAssistantsTypes.PAGINATE_VA_REQUEST:
      return {
        ...state,
        hva: {
          ...state.hva,
          loading: true,
          error: false,
          success: false,
        },
      };
    case virtualAssistantsTypes.PAGINATE_VA_SUCCESS:
      return {
        ...state,
        hva: {
          ...state.hva,
          message: action.payload.response.data.message,
          data: [...state.hva.data, ...action.payload.response.data.data],
          loading: false,
          success: true,
          hasMore: action.payload.response.data.data.length !== 0,
        },
      };
    case virtualAssistantsTypes.PAGINATE_VA_FAILURE:
      return {
        ...state,
        hva: {
          ...state.hva,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case virtualAssistantsTypes.PAGENATE_OUTDATED_VA_REQUEST:
      return {
        ...state,
        outdatedPosHva: {
          ...state.outdatedPosHva,
          loading: true,
          error: false,
          success: false,
        },
      };
    case virtualAssistantsTypes.PAGENATE_OUTDATED_VA_SUCCESS:
      return {
        ...state,
        outdatedPosHva: {
          ...state.outdatedPosHva,
          message: action.payload.response.data.message,
          data: [...state.outdatedPosHva.data, ...action.payload.response.data.data],
          loading: false,
          success: true,
          hasMore: action.payload.response.data.data.length !== 0,
        },
      };
    case virtualAssistantsTypes.PAGENATE_OUTDATED_VA_FAILURE:
      return {
        ...state,
        outdatedPosHva: {
          ...state.outdatedPosHva,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case virtualAssistantsTypes.SYNC_MONDAY_TO_SQL_REQUEST:
      return {
        ...state,
        syncMondayToSql: {
          ...state.syncMondayToSql,
          loading: true,
          error: false,
          success: false,
        },
      };
    case virtualAssistantsTypes.SYNC_MONDAY_TO_SQL_SUCCESS:
      return {
        ...state,
        syncMondayToSql: {
          ...state.syncMondayToSql,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case virtualAssistantsTypes.SYNC_MONDAY_TO_SQL_FAILURE:
      return {
        ...state,
        syncMondayToSql: {
          ...state.syncMondayToSql,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case virtualAssistantsTypes.CLEAT_SYNC_MONDAY_TO_SQL_DATA:
      return {
        ...state,
        syncMondayToSql: {
          ...state.syncMondayToSql,
          loading: false,
          message: '',
          error: false,
          data: {},
          success: false,
        },
      };
    case virtualAssistantsTypes.GET_VA_REPORT_REQUEST:
      return {
        ...state,
        getVaReport: {
          ...state.getVaReport,
          loading: true,
          error: false,
          success: false,
        },
      };
    case virtualAssistantsTypes.GET_VA_REPORT_SUCCESS:
      return {
        ...state,
        getVaReport: {
          ...state.getVaReport,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case virtualAssistantsTypes.GET_VA_REPORT_FAILURE:
      return {
        ...state,
        getVaReport: {
          ...state.getVaReport,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case virtualAssistantsTypes.GET_HEREAPI_REPORT_REQUEST:
      return {
        ...state,
        getHereApiReport: {
          ...state.getHereApiReport,
          loading: true,
          error: false,
          success: false,
        },
      };
    case virtualAssistantsTypes.GET_HEREAPI_REPORT_SUCCESS:
      return {
        ...state,
        getHereApiReport: {
          ...state.getHereApiReport,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case virtualAssistantsTypes.GET_HEREAPI_REPORT_FAILURE:
      return {
        ...state,
        getHereApiReport: {
          ...state.getHereApiReport,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
          success: false,
        },
      };
    case virtualAssistantsTypes.UPDATE_VA_LOCATIONS_REQUEST:
      return {
        ...state,
        updateVaLocations: {
          ...state.updateVaLocations,
          loading: true,
          error: false,
          success: false,
        },
      };
    case virtualAssistantsTypes.UPDATE_VA_LOCATIONS_SUCCESS:
      return {
        ...state,
        updateVaLocations: {
          ...state.updateVaLocations,
          message: action.payload.response.data.message,
          loading: false,
          success: true,
        },
      };
    case virtualAssistantsTypes.UPDATE_VA_LOCATIONS_FAILURE:
      return {
        ...state,
        updateVaLocations: {
          ...state.updateVaLocations,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
          success: false,
        },
      };
    case virtualAssistantsTypes.CLEAR_OUTDATED_VA_DATA:
      return {
        ...state,
        outdatedPosHva: {
          ...state.outdatedPosHva,
          loading: false,
          message: '',
          error: false,
          data: [],
          success: false,
        },
      };
    case virtualAssistantsTypes.CLEAR_VA_DATA:
      return {
        ...state,
        hva: {
          ...state.hva,
          loading: false,
          message: '',
          error: false,
          data: [],
          success: false,
        },
      };
    case virtualAssistantsTypes.GET_VA_WITHIN_EVENT_REQUEST:
      return {
        ...state,
        vaWithinEvent: {
          ...state.vaWithinEvent,
          loading: true,
          error: false,
          success: false,
        },
      };
    case virtualAssistantsTypes.GET_VA_WITHIN_EVENT_SUCCESS:
      return {
        ...state,
        vaWithinEvent: {
          ...state.vaWithinEvent,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case virtualAssistantsTypes.GET_VA_WITHIN_EVENT_FAILURE:
      return {
        ...state,
        vaWithinEvent: {
          ...state.vaWithinEvent,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
          success: false,
        },
      };
    case virtualAssistantsTypes.CLEAR_GET_VA_WITHIN_EVENT_DATA:
      return {
        ...state,
        vaWithinEvent: {
          ...state.vaWithinEvent,
          loading: false,
          message: '',
          error: false,
          data: [],
          success: false,
        },
      };
    case virtualAssistantsTypes.EXPORT_EVENT_RESULT_REQUEST:
      return {
        ...state,
        exportVaWithinEvent: {
          ...state.exportVaWithinEvent,
          loading: true,
          error: false,
          success: false,
        },
      };
    case virtualAssistantsTypes.EXPORT_EVENT_RESULT_SUCCESS:
      return {
        ...state,
        exportVaWithinEvent: {
          ...state.exportVaWithinEvent,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case virtualAssistantsTypes.EXPORT_EVENT_RESULT_FAILURE:
      return {
        ...state,
        exportVaWithinEvent: {
          ...state.exportVaWithinEvent,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
          success: false,
        },
      };
    case virtualAssistantsTypes.CLEAR_EXPORT_VA_WITHIN_EVENT_DATA:
      return {
        ...state,
        exportVaWithinEvent: {
          ...state.exportVaWithinEvent,
          loading: false,
          message: '',
          error: false,
          data: [],
          success: false,
        },
      };
    default:
      return state;
  }
};
