import { mapTypes } from './map.types';

const setMarkerLocation = (loc) => (dispatch) => {
  dispatch({ type: mapTypes.MARKER_LOCATION, data: loc });
};

const setEventAndLocations = (eventType, mLoc, sLoc) => (dispatch) => {
  dispatch({
    type: mapTypes.EVENTLOCATIONS,
    payload: { eventType, mLoc, sLoc },
  });
};

const setEventRadius = (eventRadius) => (dispatch) => {
  dispatch({ type: mapTypes.EVENT_RADIUS, payload: eventRadius });
};

const setEventType = (eventType) => (dispatch) => {
  dispatch({ type: mapTypes.EVENT_TYPE, payload: eventType });
};

const clearMapFields = () => (dispatch) => {
  dispatch({ type: mapTypes.CLEAR_MAP_FIELDS });
};

const setFlyToVaLocation = (location) => (dispatch) => {
  dispatch({ type: mapTypes.FLY_TO_VA_LOCATION, payload: location });
};

export const mapActions = {
  setMarkerLocation,
  setEventAndLocations,
  setEventRadius,
  setEventType,
  clearMapFields,
  setFlyToVaLocation,
};
