import axios from 'axios';
import { apiTokensTypes } from './apiTokens.types';

const getApiTokens = () => (dispatch) => {
  dispatch({ type: apiTokensTypes.GET_API_TOKEN_REQUEST });
  axios
    .get('api-tokens')
    .then((response) => {
      dispatch({
        type: apiTokensTypes.GET_API_TOKEN_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: apiTokensTypes.GET_API_TOKEN_FAILURE,
        payload: { error },
      });
    });
};

const saveApiTokens = (data) => (dispatch) => {
  dispatch({ type: apiTokensTypes.CREATE_API_TOKEN_REQUEST });
  axios
    .post('api-tokens/generate-token', data)
    .then((response) => {
      dispatch({
        type: apiTokensTypes.CREATE_API_TOKEN_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: apiTokensTypes.CREATE_API_TOKEN_FAILURE,
        payload: { error },
      });
    });
};

const revokeToken = (id) => (dispatch) => {
  dispatch({ type: apiTokensTypes.REVOKE_TOKEN_REQUEST });
  axios
    .patch(`api-tokens/revoke/${id}`)
    .then((response) => {
      dispatch({
        type: apiTokensTypes.REVOKE_TOKEN_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: apiTokensTypes.REVOKE_TOKEN_FAILURE,
        payload: { error },
      });
    });
};

const clearRevokeTokenData = () => (dispatch) => {
  dispatch({ type: apiTokensTypes.CLEAR_REVOKE_TOKEN_DATA });
};

export const apiTokensActions = {
  getApiTokens,
  saveApiTokens,
  revokeToken,
  clearRevokeTokenData,
};
