export const tptmTypes = {
  GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REQUEST: 'GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REQUEST',
  GET_TRANSFERPIPEDRIVETOMONDAYRECORD_SUCCESS: 'GET_TRANSFERPIPEDRIVETOMONDAYRECORD_SUCCESS',
  GET_TRANSFERPIPEDRIVETOMONDAYRECORD_FAILURE: 'GET_TRANSFERPIPEDRIVETOMONDAYRECORD_FAILURE',
  CLEAR_TRANSFERPIPEDRIVETOMONDAYRECORD_DATA: 'CLEAR_TRANSFERPIPEDRIVETOMONDAYRECORD_DATA',

  GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REPORT_REQUEST: 'GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REPORT_REQUEST',
  GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REPORT_SUCCESS: 'GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REPORT_SUCCESS',
  GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REPORT_FAILURE: 'GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REPORT_FAILURE',

  UPLOAD_LOCATIONS_REQUEST: 'UPLOAD_LOCATIONS_REQUEST',
  UPLOAD_LOCATIONS_SUCCESS: 'UPLOAD_LOCATIONS_SUCCESS',
  UPLOAD_LOCATIONS_FAILURE: 'UPLOAD_LOCATIONS_FAILURE',
  UPLOAD_LOCATIONS_CLEAR: 'UPLOAD_LOCATIONS_CLEAR',

  CREATE_BATCH_UPDATE_REQUEST: 'CREATE_BATCH_UPDATE_REQUEST',
  CREATE_BATCH_UPDATE_SUCCESS: 'CREATE_BATCH_UPDATE_SUCCESS',
  CREATE_BATCH_UPDATE_FAILURE: 'CREATE_BATCH_UPDATE_FAILURE',

  CLEAR_CREATE_BATCH_UPDATE_DATA: 'CLEAR_CREATE_BATCH_UPDATE_DATA',

  GET_ALL_BATCH_UPDATE_REQUEST: 'GET_ALL_BATCH_UPDATE_REQUEST',
  GET_ALL_BATCH_UPDATE_SUCCESS: 'GET_ALL_BATCH_UPDATE_SUCCESS',
  GET_ALL_BATCH_UPDATE_FAILURE: 'GET_ALL_BATCH_UPDATE_FAILURE',

  GET_JOB_STATUS_REQUEST: 'GET_JOB_STATUS_REQUEST',
  GET_JOB_STATUS_SUCCESS: 'GET_JOB_STATUS_SUCCESS',
  GET_JOB_STATUS_FAILURE: 'GET_JOB_STATUS_FAILURE',

  DOWNLOAD_JOB_RESULT_REQUEST: 'DOWNLOAD_JOB_RESULT_REQUEST',
  DOWNLOAD_JOB_RESULT_SUCCESS: 'DOWNLOAD_JOB_RESULT_SUCCESS',
  DOWNLOAD_JOB_RESULT_FAILURE: 'DOWNLOAD_JOB_RESULT_FAILURE',

  CLEAR_DOWNLOAD_JOB_DATA: 'CLEAR_DOWNLOAD_JOB_DATA',

  GET_VA_REQUEST_POS_REQUEST: 'GET_VA_REQUEST_POS_REQUEST',
  GET_VA_REQUEST_POS_SUCCESS: 'GET_VA_REQUEST_POS_SUCCESS',
  GET_VA_REQUEST_POS_FAILURE: 'GET_VA_REQUEST_POS_FAILURE',

  BATCH_UPDATE_VA_POS_REQUEST: 'BATCH_UPDATE_VA_POS_REQUEST',
  BATCH_UPDATE_VA_POS_SUCCESS: 'BATCH_UPDATE_VA_POS_SUCCESS',
  BATCH_UPDATE_VA_POS_FAILURE: 'BATCH_UPDATE_VA_POS_FAILURE',

  CLEAR_BATCH_UPDATE_VA_POS: 'CLEAR_BATCH_UPDATE_VA_POS',
};
