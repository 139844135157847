export const userTypes = {
  GET_ALL_USERS_REQUEST: 'GET_ALL_USERS_REQUEST',
  GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_FAILURE: 'GET_ALL_USERS_FAILURE',

  GET_USER_REPORT_REQUEST: 'GET_USER_REPORT_REQUEST',
  GET_USER_REPORT_SUCCESS: 'GET_USER_REPORT_SUCCESS',
  GET_USER_REPORT_FAILURE: 'GET_USER_REPORT_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  CREATE_NEW_USER_REQUEST: 'CREATE_NEW_USER_REQUEST',
  CREATE_NEW_USER_SUCCESS: 'CREATE_NEW_USER_SUCCESS',
  CREATE_NEW_USER_FAILURE: 'CREATE_NEW_USER_FAILURE',

  GET_USER_PERMISSIONS_REQUEST: 'GET_USER_PERMISSIONS_REQUEST',
  GET_USER_PERMISSIONS_SUCCESS: 'GET_USER_PERMISSIONS_SUCCESS',
  GET_USER_PERMISSIONS_FAILURE: 'GET_USER_PERMISSIONS_FAILURE',

  GET_USER_ROLES_REQUEST: 'GET_USER_ROLES_REQUEST',
  GET_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
  GET_USER_ROLES_FAILURE: 'GET_USER_ROLES_FAILURE',
};
