import axios from 'axios';
import { authTypes } from './authentication.types';

const logout = () => (dispatch) => {
  dispatch({ type: authTypes.LOGOUT_REQUEST });
  axios
    .post('/logout', {})
    .then((response) => {
      localStorage.removeItem('token');
      dispatch({ type: authTypes.LOGOUT_SUCCESS, payload: { response } });
    })
    .catch((error) => {
      dispatch({ type: authTypes.LOGOUT_FAILURE, payload: { error } });
    });
};

const login = (data) => (dispatch) => {
  dispatch({ type: authTypes.LOGIN_CLEAR_FIELDS });
  dispatch({ type: authTypes.LOGIN_REQUEST });
  axios
    .post('/login', data)
    .then((response) => {
      localStorage.setItem('token', response.data.token);
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;

      dispatch({ type: authTypes.LOGIN_SUCCESS, payload: { response } });
    })
    .catch((error) => {
      dispatch({ type: authTypes.LOGIN_FAILURE, payload: { error } });
    });
};

const clearAuthFields = () => (dispatch) => {
  dispatch({ type: authTypes.LOGIN_CLEAR_FIELDS });
  dispatch({ type: authTypes.LOGOUT_CLEAR_FIELDS });
};

export const authActions = {
  logout,
  login,
  clearAuthFields,
};
