export const eventsTypes = {
  GET_ACTIVE_EVENTS_REQUEST: 'GET_ACTIVE_EVENTS_REQUEST',
  GET_ACTIVE_EVENTS_SUCCESS: 'GET_ACTIVE_EVENTS_SUCCESS',
  GET_ACTIVE_EVENTS_FAILURE: 'GET_ACTIVE_EVENTS_FAILURE',

  SAVE_EVENT_REQUEST: 'SAVE_EVENT_REQUEST',
  SAVE_EVENT_SUCCESS: 'SAVE_EVENT_SUCCESS',
  SAVE_EVENT_FAILURE: 'SAVE_EVENT_FAILURE',

  CLEAR_SAVE_EVENTS_FIELDS: 'CLEAR_SAVE_EVENTS_FIELDS',

  GET_AN_EVENT_REQUEST: 'GET_AN_EVENT_REQUEST',
  GET_AN_EVENT_SUCCESS: 'GET_AN_EVENT_SUCCESS',
  GET_AN_EVENT_FAILURE: 'GET_AN_EVENT_FAILURE',

  GET_ALL_EVENTS_REQUEST: 'GET_ALL_EVENTS_REQUEST',
  GET_ALL_EVENTS_SUCCESS: 'GET_ALL_EVENTS_SUCCESS',
  GET_ALL_EVENTS_FAILURE: 'GET_ALL_EVENTS_FAILURE',

  GET_EVENTS_REPORT_REQUEST: 'GET_EVENTS_REPORT_REQUEST',
  GET_EVENTS_REPORT_SUCCESS: 'GET_EVENTS_REPORT_SUCCESS',
  GET_EVENTS_REPORT_FAILURE: 'GET_EVENTS_REPORT_FAILURE',

  UPDATE_AN_EVENT_REQUEST: 'UPDATE_AN_EVENT_REQUEST',
  UPDATE_AN_EVENT_SUCCESS: 'UPDATE_AN_EVENT_SUCCESS',
  UPDATE_AN_EVENT_FAILURE: 'UPDATE_AN_EVENT_FAILURE',

  CLEAR_UPDATE_AN_EVENT_DATA: 'CLEAR_UPDATE_AN_EVENT_DATA',

  DELETE_AN_EVENT_REQUEST: 'DELETE_AN_EVENT_REQUEST',
  DELETE_AN_EVENT_SUCCESS: 'DELETE_AN_EVENT_SUCCESS',
  DELETE_AN_EVENT_FAILURE: 'DELETE_AN_EVENT_FAILURE',
};
