import { tptmTypes } from './tptm.types';

const initialState = {
  getTptm: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  getTptmReport: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  uploadLocations: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  // Batch update
  createBatchUpdate: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  getBatchUpdate: {
    loading: false,
    message: '',
    error: false,
    data: [],
    success: false,
  },
  getBatchJobStatus: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  getDownloadBatchJob: {
    loading: false,
    message: '',
    error: false,
    data: {},
    success: false,
  },
  updateBatchPos: {
    loading: false,
    message: '',
    error: false,
    success: false,
  },
};

export const tptm = (state = initialState, action = null) => {
  switch (action.type) {
    case tptmTypes.GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REQUEST:
      return {
        ...state,
        getTptm: {
          ...state.getTptm,
          loading: true,
          error: false,
          success: false,
        },
      };
    case tptmTypes.GET_TRANSFERPIPEDRIVETOMONDAYRECORD_SUCCESS:
      return {
        ...state,
        getTptm: {
          ...state.getTptm,
          message: action.payload.response.data.message,
          data: [...state.getTptm.data, ...action.payload.response.data.data],
          loading: false,
          success: true,
          hasMore: action.payload.response.data.data.length !== 0,
        },
      };
    case tptmTypes.GET_TRANSFERPIPEDRIVETOMONDAYRECORD_FAILURE:
      return {
        ...state,
        getTptm: {
          ...state.getTptm,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case tptmTypes.CLEAR_TRANSFERPIPEDRIVETOMONDAYRECORD_DATA:
      return {
        ...state,
        getTptm: {
          loading: false,
          message: '',
          error: false,
          data: [],
          success: false,
        },
      };
    case tptmTypes.GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REPORT_REQUEST:
      return {
        ...state,
        getTptmReport: {
          ...state.getTptmReport,
          loading: true,
          error: false,
          success: false,
        },
      };
    case tptmTypes.GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REPORT_SUCCESS:
      return {
        ...state,
        getTptmReport: {
          ...state.getTptmReport,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case tptmTypes.GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REPORT_FAILURE:
      return {
        ...state,
        getTptmReport: {
          ...state.getTptmReport,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case tptmTypes.UPLOAD_LOCATIONS_REQUEST:
      return {
        ...state,
        uploadLocations: {
          ...state.uploadLocations,
          loading: true,
          error: false,
          success: false,
        },
      };
    case tptmTypes.UPLOAD_LOCATIONS_SUCCESS:
      return {
        ...state,
        uploadLocations: {
          ...state.uploadLocations,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case tptmTypes.UPLOAD_LOCATIONS_FAILURE:
      return {
        ...state,
        uploadLocations: {
          ...state.uploadLocations,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case tptmTypes.UPLOAD_LOCATIONS_CLEAR:
      return {
        ...state,
        uploadLocations: {
          loading: false,
          message: '',
          error: false,
          data: [],
          success: false,
        },
      };
    // Batch update
    case tptmTypes.CREATE_BATCH_UPDATE_REQUEST:
      return {
        ...state,
        createBatchUpdate: {
          ...state.createBatchUpdate,
          loading: true,
          error: false,
          success: false,
        },
      };
    case tptmTypes.CREATE_BATCH_UPDATE_SUCCESS:
      return {
        ...state,
        createBatchUpdate: {
          ...state.createBatchUpdate,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case tptmTypes.CREATE_BATCH_UPDATE_FAILURE:
      return {
        ...state,
        createBatchUpdate: {
          ...state.createBatchUpdate,
          loading: false,
          message: action.payload?.error?.response?.data
            ? action.payload?.error?.response?.data?.message
            : action.payload?.error?.message,
          error: true,
        },
      };
    case tptmTypes.CLEAR_CREATE_BATCH_UPDATE_DATA:
      return {
        ...state,
        createBatchUpdate: {
          loading: false,
          message: '',
          error: false,
          data: {},
          success: false,
        },
      };
    case tptmTypes.GET_ALL_BATCH_UPDATE_REQUEST:
      return {
        ...state,
        getBatchUpdate: {
          ...state.getBatchUpdate,
          loading: true,
          error: false,
          success: false,
        },
      };
    case tptmTypes.GET_ALL_BATCH_UPDATE_SUCCESS:
      return {
        ...state,
        getBatchUpdate: {
          ...state.getBatchUpdate,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case tptmTypes.GET_ALL_BATCH_UPDATE_FAILURE:
      return {
        ...state,
        getBatchUpdate: {
          ...state.getBatchUpdate,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case tptmTypes.GET_JOB_STATUS_REQUEST:
      return {
        ...state,
        getBatchJobStatus: {
          ...state.getBatchJobStatus,
          loading: true,
          error: false,
          success: false,
        },
      };
    case tptmTypes.GET_JOB_STATUS_SUCCESS:
      return {
        ...state,
        getBatchJobStatus: {
          ...state.getBatchJobStatus,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case tptmTypes.GET_JOB_STATUS_FAILURE:
      return {
        ...state,
        getBatchJobStatus: {
          ...state.getBatchJobStatus,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case tptmTypes.DOWNLOAD_JOB_RESULT_REQUEST:
      return {
        ...state,
        getDownloadBatchJob: {
          ...state.getDownloadBatchJob,
          loading: true,
          error: false,
          success: false,
        },
      };
    case tptmTypes.DOWNLOAD_JOB_RESULT_SUCCESS:
      return {
        ...state,
        getDownloadBatchJob: {
          ...state.getDownloadBatchJob,
          message: action.payload.response.data.message,
          data: action.payload.response.data.data,
          loading: false,
          success: true,
        },
      };
    case tptmTypes.DOWNLOAD_JOB_RESULT_FAILURE:
      return {
        ...state,
        getDownloadBatchJob: {
          ...state.getDownloadBatchJob,
          loading: false,
          message: action.payload?.error?.message,
          error: true,
        },
      };
    case tptmTypes.CLEAR_DOWNLOAD_JOB_DATA:
      return {
        ...state,
        getDownloadBatchJob: {
          loading: false,
          message: '',
          error: false,
          data: {},
          success: false,
        },
      };
    case tptmTypes.BATCH_UPDATE_VA_POS_REQUEST:
      return {
        ...state,
        updateBatchPos: {
          ...state.updateBatchPos,
          loading: true,
          error: false,
          success: false,
        },
      };
    case tptmTypes.BATCH_UPDATE_VA_POS_SUCCESS:
      return {
        ...state,
        updateBatchPos: {
          ...state.updateBatchPos,
          message: action.payload.response.data.message,
          loading: false,
          success: true,
        },
      };
    case tptmTypes.BATCH_UPDATE_VA_POS_FAILURE:
      return {
        ...state,
        updateBatchPos: {
          ...state.updateBatchPos,
          loading: false,
          message: action.payload?.error?.response?.data?.message,
          error: true,
        },
      };
    case tptmTypes.CLEAR_BATCH_UPDATE_VA_POS:
      return {
        ...state,
        updateBatchPos: {
          loading: false,
          message: '',
          error: false,
          success: false,
        },
      };
    default:
      return state;
  }
};
