import axios from 'axios';
import { syncLogTypes } from './syncLog.types';

const saveSyncLogRecord = (data) => (dispatch) => {
  dispatch({ type: syncLogTypes.SAVE_SYCNLOG_RECORD_REQUEST });
  axios
    .post('/sync-log', data)
    .then((response) => {
      dispatch({ type: syncLogTypes.SAVE_SYCNLOG_RECORD_SUCCESS, payload: { response } });
    })
    .catch((error) => {
      dispatch({ type: syncLogTypes.SAVE_SYCNLOG_RECORD_FAILURE, payload: { error } });
    });
};

const clearSaveSyncLogData = () => (dispatch) => {
  dispatch({ type: syncLogTypes.SAVE_SYCNLOG_RECORD_CLEAR });
};

const getSyncLogRecord = () => (dispatch) => {
  dispatch({ type: syncLogTypes.GET_ALL_SYNCLOG_RECORD_REQUEST });
  axios
    .get('/sync-log')
    .then((response) => {
      dispatch({ type: syncLogTypes.GET_ALL_SYNCLOG_RECORD_SUCCESS, payload: { response } });
    })
    .catch((error) => {
      dispatch({ type: syncLogTypes.GET_ALL_SYNCLOG_RECORD_FAILURE, payload: { error } });
    });
};

const clearGetSyncLogData = () => (dispatch) => {
  dispatch({ type: syncLogTypes.GET_ALL_SYNCLOG_RECORD_CLEAR });
};

const runDataClearing = () => (dispatch) => {
  dispatch({ type: syncLogTypes.RUN_DATA_CLEARING_REQUEST });
  axios
    .put('/virtual-assistants/data-clearing')
    .then((response) => {
      dispatch({ type: syncLogTypes.RUN_DATA_CLEARING_SUCCESS, payload: { response } });
    })
    .catch((error) => {
      dispatch({ type: syncLogTypes.RUN_DATA_CLEARING_FAILURE, payload: { error } });
    });
};

const clearDataClearingState = () => (dispatch) => {
  dispatch({ type: syncLogTypes.RUN_DATA_CLEARING_CLEAR });
};

export const syncLogActions = {
  saveSyncLogRecord,
  clearSaveSyncLogData,
  getSyncLogRecord,
  clearGetSyncLogData,
  runDataClearing,
  clearDataClearingState,
};
