import { mapTypes } from './map.types';

const initialState = {
  map: {},
  marker_location: {},
  event_type: '',
  multi_locations_points: [],
  single_location_point: '',
  event_radius: 0,
  va_location: { lat: 0, lng: 0, address: '' },
};

export const map = (state = initialState, action = null) => {
  switch (action.type) {
    case mapTypes.MARKER_LOCATION:
      return {
        ...state,
        marker_location: action.data,
      };
    case mapTypes.EVENTLOCATIONS:
      return {
        ...state,
        event_type: action.payload.eventType,
        multi_locations_points: action.payload.mLoc,
        single_location_point: action.payload.sLoc,
      };
    case mapTypes.EVENT_RADIUS:
      return {
        ...state,
        event_radius: action.payload,
      };
    case mapTypes.EVENT_TYPE:
      return {
        ...state,
        event_type: action.payload,
      };
    case mapTypes.CLEAR_MAP_FIELDS:
      return {
        ...state,
        // marker_location: {},
        event_type: '',
        multi_locations_points: [],
        single_location_point: '',
        event_radius: 0,
        va_location: { lat: 0, lng: 0, address: '' },
      };
    case mapTypes.FLY_TO_VA_LOCATION:
      return {
        ...state,
        va_location: action.payload,
      };
    default:
      return state;
  }
};
