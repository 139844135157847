import axios from 'axios';
import { tptmTypes } from './tptm.types';

const getTransferPipeDriveToMondayRecord = (offset, filter) => (dispatch) => {
  dispatch({ type: tptmTypes.GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REQUEST });
  axios
    .get(`transfer-pipedrive-to-monday/${offset}/offset?filter=${filter}`)
    .then((response) => {
      dispatch({ type: tptmTypes.GET_TRANSFERPIPEDRIVETOMONDAYRECORD_SUCCESS, payload: { response } });
    })
    .catch((error) => {
      dispatch({ type: tptmTypes.GET_TRANSFERPIPEDRIVETOMONDAYRECORD_FAILURE, payload: { error } });
    });
};

const clearTransferPipeDriveToMondayRecord = () => (dispatch) => {
  dispatch({ type: tptmTypes.CLEAR_TRANSFERPIPEDRIVETOMONDAYRECORD_DATA });
};

const getTransferPipeDriveToMondayRecordReport = () => (dispatch) => {
  dispatch({ type: tptmTypes.GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REPORT_REQUEST });
  axios
    .get(`transfer-pipedrive-to-monday/get-report`)
    .then((response) => {
      dispatch({ type: tptmTypes.GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REPORT_SUCCESS, payload: { response } });
    })
    .catch((error) => {
      dispatch({ type: tptmTypes.GET_TRANSFERPIPEDRIVETOMONDAYRECORD_REPORT_FAILURE, payload: { error } });
    });
};

const uploadLocations = (locations) => (dispatch) => {
  dispatch({ type: tptmTypes.UPLOAD_LOCATIONS_REQUEST });
  axios
    .post('transfer-pipedrive-to-monday/batch-location-upload', locations)
    .then((response) => {
      dispatch({ type: tptmTypes.UPLOAD_LOCATIONS_SUCCESS, payload: { response } });
    })
    .catch((error) => {
      dispatch({ type: tptmTypes.UPLOAD_LOCATIONS_FAILURE, payload: { error } });
    });
};

const clearUploadLocations = () => (dispatch) => {
  dispatch({ type: tptmTypes.UPLOAD_LOCATIONS_CLEAR });
};

/**
 * Batch update
 */

const createBatchUpdate = () => (dispatch) => {
  dispatch({ type: tptmTypes.CREATE_BATCH_UPDATE_REQUEST });
  axios
    .post('/transfer-pipedrive-to-monday/create-pos-batch-request')
    .then((response) => {
      dispatch({ type: tptmTypes.CREATE_BATCH_UPDATE_SUCCESS, payload: { response } });
    })
    .catch((error) => {
      dispatch({ type: tptmTypes.CREATE_BATCH_UPDATE_FAILURE, payload: { error } });
    });
};

const clearCreateBatchUpdate = () => (dispatch) => {
  dispatch({ type: tptmTypes.CLEAR_CREATE_BATCH_UPDATE_DATA });
};

const getAllBatchUpdate = () => (dispatch) => {
  dispatch({ type: tptmTypes.GET_ALL_BATCH_UPDATE_REQUEST });
  axios
    .get('/transfer-pipedrive-to-monday/all')
    .then((response) => {
      dispatch({
        type: tptmTypes.GET_ALL_BATCH_UPDATE_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: tptmTypes.GET_ALL_BATCH_UPDATE_FAILURE,
        payload: { error },
      });
    });
};

const getBatchJobStatus = (requestId) => (dispatch) => {
  dispatch({ type: tptmTypes.GET_JOB_STATUS_REQUEST });
  axios
    .get(`transfer-pipedrive-to-monday/get-batch-request-status/${requestId}/status`)
    .then((response) => {
      dispatch({
        type: tptmTypes.GET_JOB_STATUS_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: tptmTypes.GET_JOB_STATUS_FAILURE,
        payload: { error },
      });
    });
};

const getDownloadBatchJob = (requestId) => (dispatch) => {
  dispatch({ type: tptmTypes.DOWNLOAD_JOB_RESULT_REQUEST });
  axios
    .get(`transfer-pipedrive-to-monday/get-batch-request-result/${requestId}/result`)
    .then((response) => {
      dispatch({
        type: tptmTypes.DOWNLOAD_JOB_RESULT_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: tptmTypes.DOWNLOAD_JOB_RESULT_FAILURE,
        payload: { error },
      });
    });
};

const clearGetDownloadBatchJob = () => (dispatch) => {
  dispatch({ type: tptmTypes.CLEAR_DOWNLOAD_JOB_DATA });
};

const updateBatchPos = (data) => (dispatch) => {
  dispatch({ type: tptmTypes.BATCH_UPDATE_VA_POS_REQUEST });
  axios
    .post('transfer-pipedrive-to-monday/batch-update-pos', data)
    .then((response) => {
      dispatch({
        type: tptmTypes.BATCH_UPDATE_VA_POS_SUCCESS,
        payload: { response },
      });
    })
    .catch((error) => {
      dispatch({
        type: tptmTypes.BATCH_UPDATE_VA_POS_FAILURE,
        payload: { error },
      });
    });
};

const clearBatchUpdateVaPosData = () => (dispatch) => {
  dispatch({ type: tptmTypes.CLEAR_BATCH_UPDATE_VA_POS });
};
/**
 * END Batch update
 */

export const tptmActions = {
  getTransferPipeDriveToMondayRecord,
  clearTransferPipeDriveToMondayRecord,
  getTransferPipeDriveToMondayRecordReport,
  uploadLocations,
  clearUploadLocations,
  createBatchUpdate,
  getAllBatchUpdate,
  getBatchJobStatus,
  getDownloadBatchJob,
  clearGetDownloadBatchJob,
  updateBatchPos,
  clearBatchUpdateVaPosData,
  clearCreateBatchUpdate,
};
